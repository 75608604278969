import React, { useEffect, useRef, useState } from 'react';
import { fetchMachineData } from '../lib/tycg-api';

const Youtube = () => {
  const playerRef = useRef(null);
  const [videoIds, setVideoIds] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    // 初始获取数据
    fetchMachineData().then((res) => {
      const { youtubePlaylistUrl } = res;
      setVideoIds(youtubePlaylistUrl);
    });

    // 每五分钟获取一次数据
    const interval = setInterval(() => {
      fetchMachineData().then((res) => {
        const { youtubePlaylistUrl } = res;
        setVideoIds(youtubePlaylistUrl);
      });
    }, 600000); // 600000 毫秒 = 10 分钟

    // 清理 interval
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // 加载 YouTube Iframe API，只加载一次
    if (!apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // 当 YouTube Iframe API 已经加载时，调用 onYouTubeIframeAPIReady
      window.onYouTubeIframeAPIReady = () => {
        setApiLoaded(true);
      };
    }
  }, [apiLoaded]);

  useEffect(() => {
    if (apiLoaded && videoIds.length > 0) {
      if (playerRef.current && typeof playerRef.current.loadVideoById === 'function') {
        // 如果播放器已经实例化，更新视频
        playerRef.current.loadVideoById(videoIds[currentVideoIndex]);
      } else {
        // 初始化 YouTube 播放器
        playerRef.current = new window.YT.Player('youtube-player', {
          height: '315',
          width: '560',
          videoId: videoIds[currentVideoIndex],
          playerVars: {
            autoplay: 1, // 自动播放
            mute: 1 // 静音
          },
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          }
        });
      }
    }

    return () => {
      // 清理函数，避免内存泄漏
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
    };
  }, [apiLoaded, videoIds, currentVideoIndex]);

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoIds.length);
    }
  };

  useEffect(() => {
    if (playerRef.current && typeof playerRef.current.loadVideoById === 'function' && videoIds.length > 0) {
      playerRef.current.loadVideoById(videoIds[currentVideoIndex]);
      playerRef.current.playVideo();
    }
  }, [currentVideoIndex, videoIds]);

  return (
    <div className="video-container">
      <div id="youtube-player"></div>
    </div>
  );
};

export default Youtube;
