import moment from 'moment'
import './App.css'
import Youtube from './component/Youtube'
import { useEffect, useState } from 'react'
import { fetchMachineData, fetchAirData, fetchPeopleCountData } from './lib/tycg-api'

function App () {
  return (
    <div className="container">
      <TopBar/>
      <div className="content">
        <div className="left">
          <InfoBar/>
          <Youtube/>
        </div>
        <div className="right">
          <FactoryStatusBar/>
        </div>
      </div>
    </div>
  )
}

const TopBar = () => {
  const [info, setInfo] = useState('')
  const [date, setDate] = useState(moment().format('YYYY.MM.DD HH:mm:ss'))
  const [weather, setWeather] = useState('')
  const [topIndex, setTopIndex] = useState(0)

  useEffect(() => {
    // 初始獲取數據
    fetchMachineData().then((res) => {
      const { humidity, temperature, windSpeed } = res
      setWeather(res.weather)
      setInfo([`風速:${windSpeed}m/s`, `相對濕度:${humidity}%`, `現在溫度:${temperature}°C`])
    })

    // 每五分鐘獲取一次數據
    const interval = setInterval(() => {
      fetchMachineData().then(res => {
        const { humidity, temperature, windSpeed } = res
        setWeather(res.weather)
        setInfo([`風速:${windSpeed}m/s`, `相對濕度:${humidity}%`, `現在溫度:${temperature}°C`])
      })
    }, 300000) // 300000 毫秒 = 5 分鐘

    // 清理 interval
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // 每十秒刷新一次資訊
    const interval = setInterval(() => {
      setTopIndex(prevState => (prevState + 1) % 3)
    }, 5000)

    // 清理 interval
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // 每十秒刷新一次資訊
    const interval = setInterval(() => {
      setDate(moment().format('YYYY.MM.DD HH:mm:ss'))
    }, 1000)

    // 清理 interval
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="top-bar">
      <div className="logo">
        <img src="./img/logo1.png" alt=""/>
      </div>
      <div className="info">
        <p className="date">{date}</p>
        <p className="location">台北市</p>
        <p className="weather">{weather}</p>
        <p className="weather-info">{info[topIndex]}</p>
      </div>
    </div>
  )
}

const InfoBar = () => {
  const [pm10, setPm10] = useState('')
  const [pm25, setPm25] = useState('')
  const [db, setDB] = useState('')
  const [heatLv, setHeatLv] = useState('')
  useEffect(() => {
    // 初始獲取數據
    fetchAirData().then((res) => {
      const { pm10, pm25, db, heatLv } = res
      setPm10(pm10)
      setPm25(pm25)
      setDB(db)
      setHeatLv(heatLv)
    })

    // 每五分鐘獲取一次數據
    const interval = setInterval(() => {
      fetchAirData().then(res => {
        const { pm10, pm25, db, heatLv } = res
        setPm10(pm10)
        setPm25(pm25)
        setDB(db)
        setHeatLv(heatLv)
      })
    }, 300000) // 300000 毫秒 = 5 分鐘

    // 清理 interval
    return () => clearInterval(interval)
  }, [])
  return (
    <div className="info-bar">
      <div className="info-detail">
        <span className="info-title">PM2.5(µg/m3)</span>
        <span className="info-content" style={{ color: (pm25 < 30) ? '#009048' : '#E10C21' }}>{pm25}</span>
      </div>
      <div className="info-detail">
        <span className="info-title">PM10(µg/m3)</span>
        <span className="info-content" style={{ color: (pm10 < 70) ? '#009048' : '#E10C21' }}>{pm10}</span>
      </div>
      <div className="info-detail">
        <span className="info-title">噪音(db)</span>
        <span className="info-content" style={{ color: (db < 70) ? '#009048' : '#E10C21' }}>{db}</span>
      </div>
      <div className="info-detail">
        <span className="info-title">熱指數</span>
        <span className="info-content"
              style={{
                background: `radial-gradient(50% 50% at 50% 50%, ${heatLv.color} 0%, rgba(255, 255, 255, 0) 100%`,
                fontSize: '55px'
              }}>{heatLv.text}</span>
      </div>
    </div>
  )
}

const FactoryStatusBar = () => {
  const [countIn, setCountIn] = useState(0)
  const [countOut, setCountOut] = useState(0)
  const [countOnSite, setCountOnSite] = useState(0)
  const [companyList, setCompanyList] = useState([])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    // 初始獲取數據
    fetchPeopleCountData().then((res) => {
      const { countIn, countOut, countOnSite, companyList } = res
      setCountIn(countIn)
      setCountOut(countOut)
      setCountOnSite(countOnSite)
      setCompanyList(companyList)
    })

    // 每五分鐘獲取一次數據
    const interval = setInterval(() => {
      fetchPeopleCountData().then(res => {
        const { countIn, countOut, countOnSite, companyList } = res
        setCountIn(countIn)
        setCountOut(countOut)
        setCountOnSite(countOnSite)
        setCompanyList(companyList)
      })
    }, 10000) // 300000 毫秒 = 5 分鐘

    // 清理 interval
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // 每十秒刷新一次資訊
    const interval = setInterval(() => {

      setIndex(prevState => {
        const pageCount = (Math.ceil(companyList.length / 15))
        if (pageCount === 1) {
          return 0
        } else {
          return (prevState + 1) % pageCount
        }
      })
    }, 5000)

    // 清理 interval
    return () => clearInterval(interval)
  }, [companyList])

  return (
    <div className="status">
      <div className="overview">
        <div className="circle">
          <div className="bgi">
            <img src="./img/group1.png" alt=""/>
          </div>
          <div className="circle-content">
            <span className="people" style={{ color: '#FDC900' }}>{countIn}</span>
            <span className="people-text">進場人數</span>
          </div>
        </div>
        <div className="circle">
          <div className="bgi">
            <img src="./img/group2.png" alt=""/>
          </div>
          <div className="circle-content">
            <span className="people" style={{ color: '#E10C21' }}>{countOut}</span>
            <span className="people-text">出場人數</span>
          </div>
        </div>
        <div className="circle">
          <div className="bgi">
            <img src="./img/group3.png" alt=""/>
          </div>
          <div className="circle-content">
            <span className="people" style={{ color: '#009048' }}>{countOnSite}</span>
            <span className="people-text">在場人數</span>
          </div>
        </div>
      </div>
      <div className="factory-content">
        <FactoryList companyList={companyList} index={index}/>
        <Page count={companyList.length} index={index}/>
      </div>
    </div>
  )
}

const FactoryList = (props) => {
  const { companyList, index } = props
  const company = companyList.slice(index * 15, index * 15 + 15)
  return (
    <div className="factory-list">
      {company.map((element, index) => {
        const companyName = element.group
        const onSite = element.onSite
        return <Factory companyName={companyName} onSite={onSite} key={index}/>
      })}

    </div>
  )
}

const Factory = (props) => {
  const { onSite, companyName } = props
  return (
    <div className="factory">
      <div className="factory-detail">{onSite.toString().padStart(3, '0')}</div>
      <div className="factory-name">{companyName}</div>
    </div>
  )
}

const Page = (props) => {
  // TODO 分頁＆輪播
  const { count, index } = props
  const pageCount = Math.ceil(count / 15)
  return (
    <div className="page">
      <div className="prev">
        <img src="./img/Polygon1.png" alt=""/>
      </div>
      {count !== 0 &&
        <div className="page-index" style={{ backgroundColor: (index === 0) ? '#FDC900' : 'white' }}>1</div>}
      {pageCount > 1 && <div className="page-index"
                             style={{ backgroundColor: (index >= 1 && index < pageCount) ? '#FDC900' : 'white' }}>{pageCount > 3 ? '...' : '2'}</div>}
      {pageCount > 2 && <div className="page-index"
                             style={{ backgroundColor: (index === pageCount - 1) ? '#FDC900' : 'white' }}>{pageCount}</div>}
      <div className="next">
        <img src="./img/Polygon2.png" alt=""/>
      </div>
    </div>
  )
}

export default App
