import axios from 'axios'

export const fetchPeopleCountData = async () => {
  try {
    const response = await axios.get('https://tsgh-backend.skysharp.com.tw/people/count')
    return Promise.resolve({
      countIn: response.data.peopleStatus.entranceToday,
      countOut: response.data.peopleStatus.BACK_DOOR,
      countOnSite: response.data.peopleStatus.onSite,
      companyList: response.data.companyList
    })
  } catch (error) {
    console.log(`fetchPeopleCountData catch error: ${error.message}`)
  }
}

export const fetchMachineData = async () => {
  try {
    const response = await axios.get('https://tsgh-backend.skysharp.com.tw/ad-machine/data')
    // 取得youtube url list
    const youtubePlaylistUrl =
      response.data.youtubePlaylistUrl
        .map(url => {
          const match = url.match(/v=([a-zA-Z0-9_-]+)/)
          return match ? match[1] : null
        })
        .filter(id => id !== null)
    return Promise.resolve({
      youtubePlaylistUrl,
      weather: response.data.weather, // 天氣
      humidity: response.data.humidity, // 濕度
      temperature: response.data.temperature, // 氣溫
      windSpeed: response.data.windSpeed // 風速

    })
  } catch (error) {
    console.log(`fetchMachineData catch error: ${error.message}`)
  }
}

export const fetchAirData = async () => {
  try {
    const response = await axios.get('https://tsgh-backend.skysharp.com.tw/air03/021ACB873041')
    const heatLv = () => {
      if (response.data.hilv === 0) return { text: '未達', color: '#00CDFB' }
      else if (response.data.hilv === 1) return { text: '注意', color: '#29FD35' }
      else if (response.data.hilv === 2) return { text: '格外注意', color: '#DBFF00' }
      else if (response.data.hilv === 3) return { text: '危險', color: '#FF921E' }
      else if (response.data.hilv === 4) return { text: '極度危險', color: '#E10C21' }
    }
    return Promise.resolve({
      pm25: response.data.pm2_5,
      pm10: response.data.pm10,
      db: response.data.db,
      heatLv: heatLv()
    })
  } catch (error) {
    console.log(`fetchAirData catch error: ${error.message}`)
  }
}
